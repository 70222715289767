import React from 'react';

import { Tags } from '../components/Tags';
import Layout from '../components/Layout';
import SEO from '../components/Seo';

const TagsPage = () => (
  <Layout>
    <SEO title="Tags" />
    <Tags />
  </Layout>
);

export default TagsPage;
